/** @format */

// App.js

import React from 'react';
import Summarize from './Summarize2';

function App() {
	return (
		<div className='App'>
			<Summarize />
		</div>
	);
}

export default App;
