/** @format */

// Import React and the necessary hooks
import React, { useState } from 'react';
// Import the OpenAI SDK
import OpenAI from 'openai';

// Initialize the OpenAI client
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const Summarize = () => {
	const [assistant] = useState(
		'asst_7xx1JDdodYcm1rNqZigqk6K9'
	); /* ID from the OpenAI Assistant */
	const [thread, setThread] = useState(null);
	const [output, setOutput] = useState('');
	const [isStreaming, setIsStreaming] = useState(false);

	const interactWithExistingAssistant = async (assistantId, userMessage) => {
		//Create a thread
		const thread = await openai.beta.threads.create();
		console.log('Thread created with id:', thread.id);

		setThread(thread);

		console.log('Creating message...');
		await openai.beta.threads.messages.create(thread.id, {
			role: 'user',
			content: userMessage,
		});
		console.log('Message created');

		setIsStreaming(true);

		const run = new Promise((resolve, reject) => {
			console.log('Starting stream...');
			openai.beta.threads.runs
				.stream(thread.id, {
					assistant_id: assistantId,
				})
				.on('textCreated', (text) => {
					console.log('Text created:', text);
					setOutput((prevOutput) => `${prevOutput}\nassistant > `);
				})
				.on('textDelta', (textDelta, snapshot) => {
					console.log('Text delta:', textDelta);
					setOutput((prevOutput) => `${prevOutput}${textDelta.value}`);
				})
				.on('toolCallCreated', (toolCall) => {
					console.log('Tool call created:', toolCall);
					setOutput(
						(prevOutput) => `${prevOutput}\nassistant > ${toolCall.type}\n\n`
					);
				})
				.on('toolCallDelta', (toolCallDelta, snapshot) => {
					console.log('Tool call delta:', toolCallDelta);
					if (toolCallDelta.type === 'code_interpreter') {
						if (toolCallDelta.code_interpreter.input) {
							setOutput(
								(prevOutput) =>
									`${prevOutput}${toolCallDelta.code_interpreter.input}`
							);
						}
						if (toolCallDelta.code_interpreter.outputs) {
							setOutput((prevOutput) => `${prevOutput}\noutput >\n`);
							toolCallDelta.code_interpreter.outputs.forEach((output) => {
								if (output.type === 'logs') {
									setOutput((prevOutput) => `${prevOutput}\n${output.logs}\n`);
								}
							});
						}
					}
				})
				.on('messageDone', () => {
					setIsStreaming(false);
				})
				.on('error', (error) => {
					console.log('Error:', error);
					setIsStreaming(false);
				});
		});
	};

	return (
		<div>
			<h1>Open AI Assistant</h1>

			<button
				onClick={() =>
					interactWithExistingAssistant(
						assistant,
						'Summarize chapter about Motor neurons stimulate skeletal muscle fibers to contract'
					)
				}
				disabled={isStreaming}
			>
				{isStreaming ? 'Streaming...' : 'Run Assistant'}
			</button>

			<pre style={{ margin: '24px', lineHeight: '1.5' }}>{output}</pre>
		</div>
	);
};

export default Summarize;
